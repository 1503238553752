(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/cached-jupiter-get/assets/javascripts/cached-jupiter-get.js') >= 0) return;  svs.modules.push('/components/marketplace-data/cached-jupiter-get/assets/javascripts/cached-jupiter-get.js');
"use strict";


const {
  jupiter
} = svs.core;
const createCache = cacheTime => {
  const cache = {};
  const queue = {};
  const jupiterGet = url => jupiter.get(url, function () {
    let q = queue[url].pop();
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    while (q) {
      q.successCb(...args);
      q = queue[url].pop();
    }
    queue[url] = undefined;
    cache[url] = args;
    setTimeout(() => {
      cache[url] = undefined;
    }, cacheTime);
  }, function () {
    const {
      errorCb: cb
    } = queue[url].shift();
    cb(...arguments);
    if (queue[url].length === 0) {
      queue[url] = undefined;
      return;
    }
    jupiterGet(url);
  });
  return (url, successCb, errorCb) => {
    if (cache[url]) {
      successCb(...cache[url]);
      return;
    }
    const queueData = {
      successCb,
      errorCb
    };
    if (queue[url] !== undefined) {
      queue[url].push(queueData);
      return;
    }
    queue[url] = [queueData];
    jupiterGet(url);
  };
};
setGlobal('svs.components.marketplaceData.cachedJupiterGet.createCache', createCache);

 })(window);